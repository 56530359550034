import {
  ErrorResponse,
  ExploreEvent,
  ExploreEventList,
  ExploreProgram,
} from '@citruscamps/citrus-client'
import { faChalkboardTeacher } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FetchNextPageOptions, InfiniteQueryObserverResult } from '@tanstack/react-query'
import { Pagination } from '../../../interfaces/pagination'
import { CardSkeleton } from '../../CardSkeleton/CardSkeleton'
import { EventCard } from '../../EventCard/EventCard'
import { ShowMoreButton } from '../../ShowMoreButton/ShowMoreButton'

interface IProps {
  title: string
  events: ExploreEvent[]
  program?: ExploreProgram
  isLoading: boolean
  isError: boolean
  isFetchingNextPage: boolean
  pagination: Pagination
  fetchNextPage: (
    options?: FetchNextPageOptions | undefined,
  ) => Promise<InfiniteQueryObserverResult<ExploreEventList, ErrorResponse>>
}

export const ExploreMapEventList = ({
  title,
  events,
  program,
  isLoading,
  isError,
  isFetchingNextPage,
  fetchNextPage,
  pagination,
}: IProps) => {
  return (
    <>
      <div className="row mt-4 mt-md-0">
        <div className="col">
          <h4>{title}</h4>
        </div>
      </div>
      {(() => {
        if (isLoading) {
          return (
            <div className="row mt-2">
              {Array.from({ length: 3 })
                .fill(null)
                .map((_, i: number) => ['loading_events', i].join(':'))
                .map((key, i) => (
                  <div key={key} className="col-12 my-2 px-5 py-2">
                    <CardSkeleton />
                  </div>
                ))}
            </div>
          )
        }
        if (isError || events.length === 0) {
          return (
            <div className="row mt-2">
              <div className="col my-5 text-center">
                <div className="mb-3">
                  <FontAwesomeIcon icon={faChalkboardTeacher} size="3x" />
                </div>
                <h3>Events not found</h3>
                <p>We could not find any events, please try again later.</p>
              </div>
            </div>
          )
        }
        return (
          <div className="row mt-2">
            {events.map((event) => (
              <div key={event.id} className="col-12 my-2 px-5 py-2">
                <EventCard
                  event={event}
                  program={program || event.program}
                  showDetails={false}
                  trafficSource={{
                    utm_source: 'citrus',
                    utm_medium: 'internal',
                    utm_content: 'explore+home+map',
                    utm_term: 'event+card',
                  }}
                />
              </div>
            ))}
            {isFetchingNextPage ? (
              Array.from({ length: 3 })
                .fill(null)
                .map((_, i: number) => ['fetching_events', i].join(':'))
                .map((key, i) => (
                  <div key={key} className="col-12 my-2 px-5 py-2">
                    <CardSkeleton />
                  </div>
                ))
            ) : (
              <div className="col-12">
                <div className="mx-3">
                  <ShowMoreButton
                    {...pagination}
                    onChange={(pageParam) => fetchNextPage({ pageParam })}
                  />
                </div>
              </div>
            )}
          </div>
        )
      })()}
    </>
  )
}
