import 'mapbox-gl/dist/mapbox-gl.css'
import React, { PropsWithChildren, useEffect, useState } from 'react'
import ReactMapGL, {
  GeolocateControl,
  NavigationControl,
  ViewportProps,
  WebMercatorViewport,
} from 'react-map-gl'

interface MapProps extends Omit<Omit<ViewportProps, 'width'>, 'height'> {
  width?: string | number
  height?: string | number
  interactive?: boolean
  bounds?: [[number, number], [number, number]]
  mapStyle?: string
  className?: string
  useNavigationControl?: boolean
  resetViewport?: boolean
  onResetViewport?: () => Promise<void> | void
}

export const MapboxMap = ({
  width = '100%',
  height = '100%',
  latitude = 50.4325,
  longitude = -104.6068,
  zoom = 14,
  interactive = true,
  bounds,
  mapStyle,
  className,
  useNavigationControl = false,
  resetViewport,
  onResetViewport,
  ...props
}: PropsWithChildren<MapProps>): React.ReactElement => {
  const [viewport, setViewport] = useState<WebMercatorViewport>({
    longitude,
    latitude,
    zoom,
    ...props,
  } as any)
  useEffect(() => {
    if (
      bounds &&
      bounds.length > 0 &&
      bounds[0][0] !== bounds[1][0] &&
      bounds[0][1] !== bounds[1][1]
    ) {
      setViewport((viewport) => {
        if (Number.isNaN(viewport.width) || Number.isNaN(viewport.height)) return viewport
        try {
          const nextViewport = new WebMercatorViewport({
            ...viewport,
          }).fitBounds(bounds, {
            padding: 100,
          })
          return nextViewport
        } catch {
          return viewport
        }
      })
    }
    if (!!resetViewport) {
      setViewport((viewport) => {
        try {
          const nextViewport = new WebMercatorViewport({
            ...viewport,
            longitude,
            latitude,
            zoom,
          } as WebMercatorViewport)
          return nextViewport
        } catch {
          return viewport
        }
      })
      onResetViewport?.()
    }
  }, [
    viewport.width,
    viewport.height,
    bounds,
    latitude,
    longitude,
    zoom,
    resetViewport,
    onResetViewport,
  ])

  return (
    <ReactMapGL
      {...viewport}
      width={width}
      height={height}
      onViewportChange={(v: any) => {
        setViewport(v)
        onResetViewport?.()
      }}
      scrollZoom={interactive}
      dragPan={interactive}
      dragRotate={interactive}
      doubleClickZoom={interactive}
      touchZoom={interactive}
      touchRotate={interactive}
      mapStyle={mapStyle}
      className={className}
    >
      {!!interactive &&
        (useNavigationControl ? (
          <NavigationControl showCompass={false} className="p-2" />
        ) : (
          <GeolocateControl
            positionOptions={{ enableHighAccuracy: true }}
            trackUserLocation={true}
          />
        ))}
      {props.children}
    </ReactMapGL>
  )
}
