import { useEffect, useState } from 'react'
import { ExploreProgram } from '@citruscamps/citrus-client'
import { useFetchAttendees } from '../../../hooks/useFetchAttendees'
import { FormSkeleton } from '../../FormSkeleton/FormSkeleton'
import { getAgeFetchProps } from '../ExploreHome'
import { ExploreEventList } from './ExploreEventList'
import { ExploreProgramList } from './ExploreProgramList'
import { ExploreProgramTypesList } from './ExploreProgramTypesList'
import { DefaultEventsList } from './DefaultEventsList'
import { Address } from '../../../utils/address'

interface IProps {
  address?: Address
  initialSearch?: string
  program?: ExploreProgram
  isLoading: boolean
  onSelectCategory: (value: string | undefined) => Promise<void> | void
}

export const ExploreHomeListView = ({
  initialSearch,
  address,
  program,
  isLoading,
  onSelectCategory: handleSelectCategory,
}: IProps) => {
  const { data: attendees, isLoading: isLoadingAttendees } = useFetchAttendees({})
  const [deferSearch, setDeferSearch] = useState<string>('')
  const city = address?.city

  useEffect(() => {
    setTimeout(() => {
      setDeferSearch(initialSearch || '')
    }, 700)
  }, [initialSearch])

  if (isLoadingAttendees || isLoading) {
    return <FormSkeleton />
  }

  return (
    <>
      <ExploreProgramList
        title={`Featured programs ${city ? 'in ' + city : ''}`}
        initialSearch={deferSearch}
        fetchProps={{
          sort: 'name',
          order: 'ASC',
          filter: {
            featured: true,
            tags: undefined,
            type: undefined,
            place_name: city,
          },
        }}
      />
      <ExploreEventList
        title={`Upcoming events ${city ? `in ${city}` : ''}`}
        fetchProps={{
          sort: 'starts_at',
          order: 'ASC',
          filter: {
            type: 'camp',
            place_name: city,
            age: undefined,
            gender: undefined,
            is_online_event: undefined,
            tags: [],
          },
        }}
        program={program}
        initialSearch={deferSearch}
      />
      {(() => {
        if (!initialSearch) {
          if (attendees.length > 0) {
            const { age, min_age, max_age } = getAgeFetchProps(attendees)
            return (
              <>
                <ExploreProgramTypesList view="list" onSelectCategory={handleSelectCategory} />
                {attendees.some((a) => a.gender === 'female') && (
                  <DefaultEventsList
                    program={program}
                    fetchProps={{
                      sort: 'starts_at',
                      order: 'ASC',
                      filter: {
                        type: undefined,
                        gender: 'female',
                        tags: [],
                        place_name: city,
                        age: undefined,
                        is_online_event: undefined,
                      },
                    }}
                    title={`Explore events for girls ${city ? 'in ' + city : ''}`}
                  />
                )}
                {attendees.some((a) => a.gender === 'male') && (
                  <DefaultEventsList
                    program={program}
                    fetchProps={{
                      sort: 'starts_at',
                      order: 'ASC',
                      filter: {
                        type: undefined,
                        gender: 'male',
                        tags: [],
                        place_name: city,
                        age: undefined,
                        is_online_event: undefined,
                      },
                    }}
                    title={`Explore events for boys ${city ? 'in ' + city : ''}`}
                  />
                )}
                {(age || (min_age && max_age)) && (
                  <DefaultEventsList
                    program={program}
                    fetchProps={{
                      sort: 'starts_at',
                      order: 'ASC',
                      filter: {
                        type: undefined,
                        min_age,
                        max_age,
                        age,
                        tags: [],
                        place_name: city,
                        gender: undefined,
                        is_online_event: undefined,
                      },
                    }}
                    title={
                      age
                        ? `Find events for ${age} year olds ${city ? 'in ' + city : ''}`
                        : `Find events for youth ages ${min_age + '-' + max_age} ${
                            city ? 'in ' + city : ''
                          }`
                    }
                  />
                )}
              </>
            )
          }
          return <ExploreProgramTypesList view="list" onSelectCategory={handleSelectCategory} />
        }
      })()}
    </>
  )
}
