import { faAngleRight } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState } from 'react'
import { ProgramCategories } from '../../../constants/program-categories'
import { FieldCarousel } from '../../FieldCarousel/FieldCarousel'
import { Icon } from '../../Icon/Icon'

export const getCategorySource = (category: string): string => {
  switch (category.toLowerCase().split(' ').join('_')) {
    case 'academy':
    case 'spring_academy':
    case 'soccer':
      return 'https://source.unsplash.com/b5R-EetfgwM/'
    case 'summer_camp':
    case 'camps':
      return 'https://source.unsplash.com/Wqt15MLirEQ/'
    case 'all_day':
    case 'day':
    case 'traditional':
      return 'https://source.unsplash.com/DqgMHzeio7g'
    case 'winter_programs':
      return 'https://source.unsplash.com/25W3sMihPyA/800x500'
    case 'basketball':
      return 'https://source.unsplash.com/G38HyRyx3Cw'
    case 'baseball':
      return 'https://source.unsplash.com/NIrRqqrs0Fs'
    case 'computer_&_tech':
    case 'engineering':
    case 'education':
      return 'https://source.unsplash.com/-GoKFTYnRoQ'
    case 'ball_hockey':
    case 'power_skating':
    case 'powerskating':
    case 'skating':
      return 'https://source.unsplash.com/QRyI3nQwjbg/600x800'
    case 'hockey_skills':
    case 'hockey_development':
    case 'hockey_shooting':
    case 'hockey':
    case 'ringette':
      return 'https://source.unsplash.com/QWsdUMj8amc'
    case 'martial_arts':
    case 'youth_empowerment':
      return 'https://source.unsplash.com/b24JhTZlxSg/800x950'
    case 'leagues':
    case 'multi-sports':
    case 'elite_team':
    case 'sports':
      return 'https://source.unsplash.com/ymVaGKsBQXM'
    case 'tennis':
      return 'https://source.unsplash.com/2FKTyJqfWX8'
    case 'volleyball':
      return 'https://source.unsplash.com/-PkEytgjLm0'
    case 'other':
    default:
      return 'https://source.unsplash.com/g-dZ1h7nQ0E'
  }
}

interface IProps {
  view: 'map' | 'list'
  selectedCategory?: string
  onSelectCategory: (value: string | undefined) => Promise<void> | void
}

export const ExploreProgramTypesList = ({
  view,
  selectedCategory,
  onSelectCategory: handleSelectCategory,
}: IProps) => {
  const [displayedCategories, setDisplayedCategories] = useState<number>(10)

  const visibleCategories: string[] =
    selectedCategory &&
    !ProgramCategories.slice(0, displayedCategories).some((c) => c === selectedCategory)
      ? ProgramCategories.slice(0, displayedCategories - 1).concat(selectedCategory)
      : ProgramCategories.slice(0, displayedCategories)
  if (view === 'list') {
    return (
      <>
        <div className="row mt-5 mb-4">
          <div className="col">
            <h4>Explore popular categories</h4>
          </div>
        </div>
        <FieldCarousel
          className="col-12"
          columnClassName="col-6 col-sm-6 col-md-4 col-lg-3"
          length={ProgramCategories.length}
        >
          {ProgramCategories.map((category) => {
            const src = getCategorySource(category)
            return (
              <div key={category} className="text-center">
                <button
                  type="button"
                  className="btn btn-link text-dark pt-2"
                  onClick={async () => await handleSelectCategory(category)}
                >
                  <div className="position-relative" style={{ width: '11rem', height: '11rem' }}>
                    <div
                      className="position-absolute rounded-circle"
                      style={{
                        width: '100%',
                        height: '100%',
                        backgroundColor: '#000000',
                        opacity: '50%',
                      }}
                    ></div>
                    <Icon
                      className="avatar-img rounded-circle border shadow-sm"
                      src={src}
                      alt={`${category}-icon`}
                    />

                    <div
                      className="position-absolute h5 text-white"
                      style={{ top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}
                    >
                      {category.charAt(0).toUpperCase() + category.split('_').join(' ').slice(1)}
                    </div>
                  </div>
                </button>
              </div>
            )
          })}
        </FieldCarousel>
      </>
    )
  }
  if (view === 'map') {
    return (
      <div className="row mt-4 h-100 mb-4 pb-2">
        <div className="col-12 text-center">
          <button
            type="button"
            className={`btn btn-link text-decoration-none ${
              typeof selectedCategory === 'undefined' ? 'font-weight-bold border-bottom' : ''
            }`}
            onClick={() => handleSelectCategory(undefined)}
          >
            All
          </button>
          {visibleCategories.map((f) => (
            <span key={f}>
              <button
                type="button"
                className={`btn btn-link text-decoration-none ${
                  selectedCategory === f ? 'font-weight-bold border-bottom' : ''
                }`}
                onClick={() => handleSelectCategory(f)}
              >
                {f.charAt(0).toUpperCase() + f.split('_').join(' ').slice(1)}
              </button>
            </span>
          ))}
          {ProgramCategories.length > displayedCategories && (
            <button
              type="button"
              className="btn btn-link px-0"
              onClick={() => setDisplayedCategories(displayedCategories + 9)}
            >
              <FontAwesomeIcon icon={faAngleRight} />
            </button>
          )}
        </div>
      </div>
    )
  }
  return null
}
