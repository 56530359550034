import React, { useState } from 'react'

interface IPosition {
  x: number
  y: number
}

interface IState {
  rafPending: boolean
  initialTouchPos: IPosition | null
  lastTouchPos: IPosition | null
  listenMouse: boolean
}

interface SwipeCarousel {
  handleGestureStart: (
    evt:
      | React.TouchEvent<HTMLDivElement>
      | React.PointerEvent<HTMLDivElement>
      | React.MouseEvent<HTMLDivElement>,
  ) => any
  handleGestureMove: (
    evt:
      | React.TouchEvent<HTMLDivElement>
      | React.PointerEvent<HTMLDivElement>
      | React.MouseEvent<HTMLDivElement>,
  ) => any
  handleGestureEnd: (
    evt:
      | React.TouchEvent<HTMLDivElement>
      | React.PointerEvent<HTMLDivElement>
      | React.MouseEvent<HTMLDivElement>,
  ) => any
  listenMouse: boolean
}

interface IProps {
  slopValue: number
  onLeft: () => any
  onRight: () => any
}

export const useSwipeCarousel = ({
  slopValue,
  onLeft: handleLeft,
  onRight: handleRight,
}: IProps): SwipeCarousel => {
  const [state, setState] = useState<IState>({
    rafPending: false,
    initialTouchPos: null,
    lastTouchPos: null,
    listenMouse: false,
  })

  const getGesturePointFromEvent = (
    evt:
      | React.TouchEvent<HTMLDivElement>
      | React.PointerEvent<HTMLDivElement>
      | React.MouseEvent<HTMLDivElement>,
  ) => {
    let point: IPosition
    if ('targetTouches' in evt) {
      point = {
        x: evt.targetTouches[0].clientX,
        y: evt.targetTouches[0].clientY,
      }
    } else {
      // Either Mouse event or Pointer Event
      point = {
        x: evt.clientX,
        y: evt.clientY,
      }
    }
    return point
  }

  const handleGestureStart = (
    evt:
      | React.TouchEvent<HTMLDivElement>
      | React.PointerEvent<HTMLDivElement>
      | React.MouseEvent<HTMLDivElement>,
  ) => {
    if ('touches' in evt && evt.touches.length > 0) {
      return
    }

    let value = { ...state }
    // Add the move and end listeners
    if ('pointerId' in evt && window.PointerEvent) {
      ;(evt.target as any).setPointerCapture(evt.pointerId)
    } else {
      // Add Mouse Listeners
      value.listenMouse = true
    }
    setState({
      ...value,
      initialTouchPos: getGesturePointFromEvent(evt),
    })
  }

  const handleGestureMove = (
    evt:
      | React.TouchEvent<HTMLDivElement>
      | React.PointerEvent<HTMLDivElement>
      | React.MouseEvent<HTMLDivElement>,
  ) => {
    let value = { ...state }
    if (!value.initialTouchPos) {
      return
    }

    value.lastTouchPos = getGesturePointFromEvent(evt)

    if (value.rafPending) {
      setState(value)
      return
    }

    setState({ ...value, rafPending: true })
  }

  const handleGestureEnd = (
    evt:
      | React.TouchEvent<HTMLDivElement>
      | React.PointerEvent<HTMLDivElement>
      | React.MouseEvent<HTMLDivElement>,
  ) => {
    if ('touches' in evt && evt.touches.length > 0) {
      return
    }

    let value = { ...state }
    value.rafPending = false

    // Remove Event Listeners
    if ('pointerId' in evt && window.PointerEvent) {
      ;(evt.target as any).releasePointerCapture(evt.pointerId)
    } else {
      // Remove Mouse Listeners
      value.listenMouse = false
    }

    updateSwipeRestPosition(value)

    value.initialTouchPos = null
    setState({ ...value })
  }

  const updateSwipeRestPosition = ({ initialTouchPos, lastTouchPos, ...value }: IState) => {
    if (!initialTouchPos || !lastTouchPos || !slopValue) {
      return
    }
    const differenceInX = initialTouchPos.x - lastTouchPos.x
    // Check if we need to change state to left or right based on slop value
    if (Math.abs(differenceInX) > slopValue) {
      if (differenceInX > 0) {
        handleLeft()
      } else {
        handleRight()
      }
    }
    setState({
      rafPending: false,
      initialTouchPos: null,
      lastTouchPos: null,
      listenMouse: false,
    })
  }

  return { handleGestureStart, handleGestureMove, handleGestureEnd, listenMouse: state.listenMouse }
}
