import { useRouter } from 'next/router'
import { Dispatch, useState } from 'react'
import {
  FetchNextPageOptions,
  InfiniteQueryObserverResult,
  useInfiniteQuery,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query'
import {
  ErrorResponse,
  ExploreApi,
  ExploreProgram,
  ExploreProgramList,
  ProgramType,
} from '@citruscamps/citrus-client'
import { DefaultDetailsPagination, UnlimitedPagination } from '../../../constants/pagination'
import { useCookiePreferences } from '../../../hooks/useCookiePreferences'
import { useSearchValue } from '../../../hooks/useSearchValue'
import { useToast } from '../../../hooks/useToast'
import { Pagination } from '../../../interfaces/pagination'
import { generateApiConfig } from '../../../utils/client-config'
import { generateItemKey, generateListKey } from '../../../utils/key-generator'
import { useRequestHandler } from '../../../hooks/useRequestHandler'

export const DefaultFetchProps: ProgramFetchProps = {
  sort: 'name',
  order: 'ASC',
  filter: {
    featured: undefined,
  },
}

export interface ProgramFilterProps {
  featured?: boolean
  place_name?: string
  tags?: string[]
  type?: ProgramType[]
}

export interface ProgramFetchProps {
  sort?: string
  order?: 'ASC' | 'DESC'
  filter: ProgramFilterProps
  filterName?: string
}

interface IProps {
  pagination?: Pagination
  fetchProps?: ProgramFetchProps
  overwriteFilters?: boolean
  enabled?: boolean
  initialSearch?: string
}

interface FetchPrograms {
  data: ExploreProgram[]
  error: ErrorResponse | null
  isError: boolean
  isLoading: boolean
  fetchProps: ProgramFetchProps
  pagination: Pagination
  search: string
  setFetchProps: (value?: ProgramFetchProps, pagination?: Pagination) => void
  setPagination: Dispatch<Pagination>
  setSearch: Dispatch<string>
}

export const useFetchExplorePrograms = ({
  pagination: initialPagination = DefaultDetailsPagination,
  overwriteFilters,
  enabled = true,
  ...props
}: IProps): FetchPrograms => {
  const { requestHandler } = useRequestHandler()
  const { asPath } = useRouter()
  const { setToast } = useToast()
  const queryClient = useQueryClient()
  const [pagination, setPagination] = useState<Pagination>(initialPagination)
  const initialFetchProps: ProgramFetchProps = props.fetchProps || DefaultFetchProps
  const [fetchProps, setFetchProps] = useCookiePreferences<ProgramFetchProps>(
    `program-filter-${asPath}`,
    initialFetchProps,
    {
      sameSite: false,
    },
  )
  const { search, deferSearch, setSearch, isSearching } = useSearchValue('', {
    onDefer: () => setPagination(initialPagination),
  })
  const queryKeys = generateListKey({
    type: 'program',
    pagination,
    sort: fetchProps.sort,
    order: fetchProps.order,
    query: fetchProps.filter,
    search: deferSearch,
  })

  const {
    data: resp,
    isInitialLoading: isLoading,
    isError,
    error,
  } = useQuery<ExploreProgramList, ErrorResponse>(
    queryKeys,
    async ({ signal }) => {
      const client = new ExploreApi(generateApiConfig())
      const skip = pagination.limit ? pagination.page * pagination.limit : undefined
      const response = await requestHandler<ExploreProgramList>(
        () =>
          client.findExplorePrograms(
            {
              limit: pagination.limit,
              skip,
              order: fetchProps.order,
              sort: fetchProps.sort,
              type:
                fetchProps?.filter?.type && fetchProps.filter.type.length > 0
                  ? fetchProps?.filter?.type
                  : undefined,
              featured:
                typeof fetchProps.filter?.featured !== 'undefined'
                  ? fetchProps.filter.featured
                  : undefined,
              search: deferSearch || undefined,
              tags:
                fetchProps?.filter?.tags && fetchProps.filter.tags.length > 0
                  ? fetchProps?.filter?.tags
                  : undefined,
              place_name: fetchProps?.filter?.place_name || undefined,
            },
            { signal },
          ),
        {
          toast: { setToast },
        },
      )
      response.data.forEach((item) =>
        queryClient.setQueryData(
          generateItemKey({
            type: 'program',
            id: item.id,
          }),
          item,
        ),
      )
      return response
    },
    { enabled },
  )

  const _setFetchProps = (val?: ProgramFetchProps) => {
    setPagination(initialPagination)
    return setFetchProps(val || DefaultFetchProps)
  }

  return {
    data: resp?.data || [],
    error,
    fetchProps,
    isError,
    isLoading: isLoading || isSearching,
    pagination: {
      ...pagination,
      count: resp?.count || pagination.count,
      total: resp?.total || pagination.total,
      page: resp?.page || pagination.page,
      page_count: resp?.page_count || pagination.page_count,
    },
    search,
    setFetchProps: _setFetchProps,
    setPagination,
    setSearch,
  }
}

interface FetchInfinitePrograms {
  data: ExploreProgram[]
  error: ErrorResponse | null
  fetchProps: ProgramFetchProps
  isError: boolean
  isFetching: boolean
  isFetchingNextPage: boolean
  isLoading: boolean
  hasNextPage: boolean
  pagination: Pagination
  search: string
  fetchNextPage: (
    options?: FetchNextPageOptions | undefined,
  ) => Promise<InfiniteQueryObserverResult<ExploreProgramList, ErrorResponse>>
  setFetchProps: (value?: ProgramFetchProps) => void
  setSearch: Dispatch<string>
}

export const useFetchInfiniteExplorePrograms = ({
  pagination = DefaultDetailsPagination,
  overwriteFilters,
  initialSearch = '',
  enabled = true,
  ...props
}: IProps): FetchInfinitePrograms => {
  const { requestHandler } = useRequestHandler()
  const { asPath } = useRouter()
  const initialFetchProps: ProgramFetchProps = props.fetchProps || DefaultFetchProps
  const [fetchProps, setFetchProps] = useCookiePreferences<ProgramFetchProps>(
    `program-filter-${asPath}`,
    initialFetchProps,
    {
      sameSite: false,
    },
  )
  const { search, deferSearch, setSearch, isSearching } = useSearchValue('', {})
  const queryKeys = generateListKey({
    type: 'program',
    pagination: UnlimitedPagination,
    sort: fetchProps.sort,
    order: fetchProps.order,
    query: fetchProps.filter,
    search: deferSearch || initialSearch,
  })
  const {
    data,
    error,
    isError,
    isFetching,
    isFetchingNextPage,
    isInitialLoading: isLoading,
    fetchNextPage,
    hasNextPage = false,
  } = useInfiniteQuery<ExploreProgramList, ErrorResponse>(
    queryKeys,
    async ({ signal, pageParam: pg }) => {
      pg = pg || pagination
      const client = new ExploreApi(generateApiConfig())
      const skip = pg.limit ? pg.page * pg.limit : undefined
      const resp: ExploreProgramList = await requestHandler<ExploreProgramList>(() =>
        client.findExplorePrograms(
          {
            limit: pagination.limit,
            skip,
            order: fetchProps.order,
            sort: fetchProps.sort,
            type:
              fetchProps?.filter?.type && fetchProps.filter.type.length > 0
                ? fetchProps?.filter?.type
                : undefined,
            featured:
              typeof fetchProps.filter?.featured !== 'undefined'
                ? fetchProps.filter.featured
                : undefined,
            search: deferSearch || initialSearch || undefined,
            tags:
              fetchProps?.filter?.tags && fetchProps.filter.tags.length > 0
                ? fetchProps?.filter?.tags
                : undefined,
            place_name: fetchProps?.filter?.place_name || undefined,
          },
          { signal },
        ),
      )
      return resp
    },
    {
      getNextPageParam: (resp: ExploreProgramList): Pagination | undefined => {
        if (!resp.page_count || resp.page < resp.page_count - 1) {
          return {
            ...pagination,
            count: resp?.count,
            total: resp?.total,
            page: resp?.page + 1,
            page_count: resp?.page_count,
          }
        }
        return undefined
      },
      getPreviousPageParam: (resp: ExploreProgramList): Pagination | undefined => {
        if (!resp.page_count || resp.page <= resp.page_count) {
          return {
            ...pagination,
            count: resp?.count,
            total: resp?.total,
            page: resp?.page - 1,
            page_count: resp?.page_count,
          }
        }
        return undefined
      },
      enabled,
    },
  )

  const _setFetchProps = (val?: ProgramFetchProps) => {
    return setFetchProps(val || DefaultFetchProps)
  }

  const resp = [...(data?.pages || [])].pop()
  return {
    data: (data?.pages || []).reduce<ExploreProgram[]>((list, p) => [...list, ...p.data], []) || [],
    error,
    fetchProps,
    hasNextPage,
    isError,
    isFetching,
    isFetchingNextPage,
    isLoading: isLoading || isSearching,
    pagination: {
      ...pagination,
      count: resp?.count || pagination.count,
      total: resp?.total || pagination.total,
      page: resp?.page || pagination.page,
      page_count: resp?.page_count || pagination.page_count,
    },
    search,
    fetchNextPage,
    setFetchProps: _setFetchProps,
    setSearch,
  }
}
