import React from 'react'
import { Pagination } from '../../interfaces/pagination'

export interface IProps extends Pagination {
  onChange: (pagination: Pagination) => void
}

export const ShowMoreButton = (
  props: React.PropsWithChildren<IProps>,
): React.ReactElement | null => {
  const { count, total, limit = 0, page, page_count = 0, onChange: handleChange, children } = props
  if (page + 1 >= page_count || count >= total) {
    return null
  }

  return (
    <button
      type="button"
      className="btn btn-link px-0"
      onClick={() =>
        handleChange({
          limit,
          count,
          total,
          page: page + 1,
          page_count,
        })
      }
    >
      {children || 'See more'}
    </button>
  )
}
