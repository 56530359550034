import { faCalendarAlt, faLocationDot } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { DateTime } from 'luxon'
import { useRouter } from 'next/router'
import React, { useEffect, useMemo, useState } from 'react'
import { Marker, Popup } from 'react-map-gl'
import { ExploreEvent, Venue } from '@citruscamps/citrus-client'
import { ROUTES } from '../../../constants/routes'
import { Address } from '../../../utils/address'
import { DefaultCitrusBrandAccentColor } from '../../../utils/branding-utils'
import { MapboxMap } from '../../MapboxMap/MapboxMap'
import { getBounds } from '../../MapboxMap/utils/map'

interface IMapPin {
  program_id: string
  event_id: string
  event_name: string
  program_name: string
  timezone: string
  starts_at: Date
  ends_at: Date
  venue: Venue
}

interface IProps {
  events?: ExploreEvent[]
  isLoadingEvents?: boolean
  address?: Address
  mapHeight: number
}

export const ExploreHomeMap = ({ events = [], address, isLoadingEvents, mapHeight }: IProps) => {
  const router = useRouter()
  const [showPopup, setShowPopup] = useState<{
    pin: IMapPin
    longitude: number
    latitude: number
  }>()
  const pins = events.reduce((prev: IMapPin[], curr: ExploreEvent): IMapPin[] => {
    if (curr.venues && (curr.venues || []).length > 0) {
      const pin: IMapPin[] = curr.venues.map((v) => ({
        program_id: curr.program.id,
        event_id: curr.id,
        event_name: curr.name,
        program_name: curr.program?.name,
        timezone: curr.timezone,
        starts_at: curr.starts_at,
        ends_at: curr.ends_at,
        venue: v,
      }))
      if (prev.length === 0) {
        return pin
      }
      const newPins: IMapPin[] = pin.map((p: IMapPin) => {
        if (
          p.venue.geometry &&
          prev.some(
            (value) =>
              value.venue.geometry?.coordinates?.[0] === p.venue.geometry?.coordinates?.[0] &&
              value.venue.geometry?.coordinates?.[1] === p.venue.geometry?.coordinates?.[1],
          )
        ) {
          p.venue.geometry.coordinates = [
            p.venue.geometry?.coordinates[0],
            (p.venue.geometry?.coordinates[1] || 0) + 0.001,
          ]
          return p
        }
        return p
      })
      return [...prev, ...newPins]
    }
    return prev
  }, [])
  const bounds = useMemo(
    () =>
      getBounds(
        (pins
          ?.map<number[] | undefined>((p) => p.venue.geometry?.coordinates)
          .filter(Boolean) as any) || [],
      ),
    [pins],
  )
  const markers = useMemo(
    () =>
      pins
        ?.map((pin: IMapPin) => {
          const address: Address | undefined = pin.venue
            ? Address.fromObject(pin.venue as any)
            : undefined
          const long: number | undefined = address?.geometry?.coordinates[0]
          const lat: number | undefined = address?.geometry?.coordinates[1]
          return (
            !!long &&
            !!lat && (
              <Marker
                latitude={lat}
                longitude={long}
                offsetTop={-10}
                key={`marker-${pin.venue.id}`}
              >
                <span
                  className="marker-pin border-white"
                  style={{ cursor: 'pointer' }}
                  onClick={() => setShowPopup({ pin, longitude: long, latitude: lat })}
                >
                  <FontAwesomeIcon
                    fixedWidth={true}
                    icon={faLocationDot}
                    color={DefaultCitrusBrandAccentColor}
                  />
                </span>
              </Marker>
            )
          )
        })
        .filter(Boolean) || [],
    [pins],
  )
  const [isLoadingPins, setIsLoadingPins] = useState<boolean>(false)
  const longitude =
    pins?.[0]?.venue.geometry?.coordinates[0] || address?.geometry?.coordinates[0] || undefined
  const latitude =
    pins?.[0]?.venue.geometry?.coordinates[1] || address?.geometry?.coordinates[1] || undefined

  useEffect(() => {
    setIsLoadingPins(!!isLoadingEvents)
  }, [isLoadingEvents])

  return (
    <div>
      <MapboxMap
        height={mapHeight}
        width="100%"
        latitude={latitude}
        longitude={longitude}
        zoom={14}
        useNavigationControl={true}
        bounds={bounds}
        resetViewport={pins.length <= 1 || (!isLoadingEvents && !!isLoadingPins)}
        pitch={0}
        bearing={0}
        className="rounded-xs"
      >
        {markers}
        {(() => {
          if (showPopup) {
            return (
              <Popup
                offsetLeft={20}
                latitude={showPopup.latitude}
                longitude={showPopup.longitude}
                closeButton={true}
                onClose={() => setShowPopup(undefined)}
              >
                <div className="p-2 pt-3 " style={{ cursor: 'default', maxWidth: 250 }}>
                  <h6>{showPopup.pin.event_name}</h6>
                  <div className="text-muted pb-2">{showPopup.pin.program_name}</div>
                  <div className="text-muted small pb-2">
                    <FontAwesomeIcon icon={faLocationDot} className="mr-2" />
                    {Address.fromObject(showPopup.pin.venue).toLocaleString(
                      Address.MAILING_ADDRESS,
                    )}
                  </div>
                  <div className="text-muted small pb-2">
                    <FontAwesomeIcon icon={faCalendarAlt} className="mr-2" />
                    {(() => {
                      const zone = showPopup.pin.timezone
                      const start_at = DateTime.fromJSDate(new Date(showPopup.pin.starts_at), {
                        zone,
                      })
                      const ends_at = DateTime.fromJSDate(new Date(showPopup.pin.ends_at), {
                        zone,
                      })
                      return start_at.hasSame(ends_at, 'day')
                        ? `${start_at.toFormat(`MMMM d yyyy, t`)} - ${ends_at.toFormat(`t ZZZZ`)}`
                        : `${start_at.toFormat(`MMMM d yyyy, t`)} - ${ends_at.toFormat(
                            `MMMM d yyyy, t ZZZZ`,
                          )}`
                    })()}
                  </div>
                  <div className="text-center">
                    <button
                      type="button"
                      className="btn btn-outline-primary"
                      onClick={async () =>
                        await router.push({
                          pathname: ROUTES.EVENT_DETAILS,
                          query: {
                            program_id: showPopup.pin.program_id,
                            event_id: showPopup.pin.event_id,
                          },
                        })
                      }
                    >
                      View event
                    </button>
                  </div>
                </div>
              </Popup>
            )
          }
        })()}
      </MapboxMap>
    </div>
  )
}
