interface IProps {
  hostname?: string
}

export const ExploreHomeHeader = ({ hostname }: IProps) => {
  return (
    <>
      <div className="wave-one" style={{ position: 'absolute', zIndex: -1, marginTop: '-20rem' }} />
      <header className="citrus-header" style={{ height: 'unset' }}>
        <div className="container">
          <div className="row">
            <div className="col-xs-12 col-lg-6">
              <div className="py-5 hidden-xs" />
              <h1 className="display-4">Create an impact in youth&apos;s lives</h1>
              <div className="py-2 hidden-xs" />
              <a
                className="btn btn-primary text-white"
                href={`${process.env.REACT_APP_DASHBOARD_URL}/signup?utm_source=${
                  hostname?.replace('http://', '').replace('https://', '') ||
                  'register.citruscamps.com'
                }&utm_campaign=get_started`}
              >
                Become an organizer
              </a>
              <div className="py-5 d-none d-sm-block" />
              <div className="py-3 d-none d-lg-block" />
            </div>
            <div className="col-xs-12 d-none d-lg-inline-block col-lg-6">
              <div className="py-5 hidden-xs" />
              <img
                src="/imgs/basketball.png"
                alt="Graphic with youth adults playing basketball"
                className="img-fluid"
                style={{ marginTop: '-8rem' }}
              />
            </div>
          </div>
        </div>
      </header>
    </>
  )
}
