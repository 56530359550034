import { config } from '@fortawesome/fontawesome-svg-core'
import { DateTime, Interval } from 'luxon'
import { useRouter } from 'next/router'
import { useCallback, useState } from 'react'
import { Attendee, Venue } from '@citruscamps/citrus-client'
import { ROUTES } from '../../constants/routes'
import { useProgram } from '../../hooks/useFetchProgram'
import { useGeolocation } from '../../hooks/useGeolocation'
import { Address } from '../../utils/address'
import { toSingleQueryValue } from '../../utils/query-params'
import { ExploreHomeListView } from './components/ExploreHomeListView'
import { ExploreHomeMapView } from './components/ExploreHomeMapView'
import { ExploreProgramTypesList } from './components/ExploreProgramTypesList'
import { HomeSearchBar } from './components/HomeSearchBar'

config.autoAddCss = false

interface AgeFetchProps {
  age: number | undefined
  min_age: number | undefined
  max_age: number | undefined
}

export const getAgeFetchProps = (attendees: Attendee[]): AgeFetchProps => {
  const initialAttendee = attendees.find((a) => !!a.date_of_birth)
  if (!initialAttendee || !initialAttendee.date_of_birth) {
    return {
      min_age: undefined,
      max_age: undefined,
      age: undefined,
    }
  }
  const initialAge: number = Math.floor(
    Interval.fromDateTimes(
      DateTime.fromFormat(initialAttendee.date_of_birth, 'yyyy-MM-dd'),
      DateTime.local(),
    ).toDuration('years').years,
  )
  const min_age: number = attendees.reduce((prev: number, curr: Attendee) => {
    const _age = curr.date_of_birth
      ? Math.floor(
          Interval.fromDateTimes(
            DateTime.fromFormat(curr.date_of_birth, 'yyyy-MM-dd'),
            DateTime.local(),
          ).toDuration('years').years,
        )
      : undefined
    if (_age && _age < prev) {
      return _age
    }
    return prev
  }, initialAge)
  const max_age: number = attendees.reduce((prev: number, curr: Attendee) => {
    const _age = curr.date_of_birth
      ? Math.floor(
          Interval.fromDateTimes(
            DateTime.fromFormat(curr.date_of_birth, 'yyyy-MM-dd'),
            DateTime.local(),
          ).toDuration('years').years,
        )
      : undefined
    if (_age && _age > prev) {
      return _age
    }
    return prev
  }, initialAge)

  if (min_age === max_age) {
    return { age: min_age, min_age: undefined, max_age: undefined }
  }
  return {
    age: undefined,
    min_age,
    max_age,
  }
}

export const ExploreHome = ({ clientIp }: { clientIp?: string }) => {
  const { program } = useProgram()
  const router = useRouter()
  const [search, setSearch] = useState<string>()
  const [selectedVenue, setSelectedVenue] = useState<Venue>()
  const { category, ...query } = toSingleQueryValue(router.query)
  const view: 'list' | 'map' = query.view === 'list' || query.view === 'map' ? query.view : 'list'
  const {
    data: geolocation,
    source,
    isLoading,
    handleRequestLocation,
  } = useGeolocation({
    types: ['place', 'region'],
    clientIp,
  })

  const setView = useCallback(
    (v: 'list' | 'map', filterCategory?: string) => {
      router.push(
        {
          pathname: ROUTES.HOME,
          query: filterCategory ? { view: v, category: filterCategory } : { view: v },
        },
        undefined,
        {
          shallow: true,
        },
      )
    },
    [router],
  )

  const address: Address | undefined = selectedVenue
    ? Address.fromObject(selectedVenue)
    : geolocation

  if (view === 'map') {
    return (
      <>
        <div className="container">
          <HomeSearchBar
            view={view}
            source={source}
            search={search}
            address={address}
            isLoading={isLoading}
            onChangeSearch={async (search: string) => {
              await setSearch(search)
            }}
            onSelectLocation={(value: Venue) => {
              setSelectedVenue(value)
            }}
            onChangeView={(value: 'list' | 'map') => setView(value)}
            onRequestLocation={handleRequestLocation}
          />
          <ExploreProgramTypesList
            view="map"
            selectedCategory={category}
            onSelectCategory={(value: string | undefined) => {
              setView('map', value)
            }}
          />
          <ExploreHomeMapView
            address={address}
            category={category}
            program={program}
            initialSearch={search}
          />
        </div>
      </>
    )
  }
  return (
    <div className="container">
      <HomeSearchBar
        view={view}
        source={source}
        address={address}
        search={search}
        isLoading={isLoading}
        onChangeSearch={async (search: string) => {
          await setSearch(search)
        }}
        onSelectLocation={(value: Venue) => {
          setSelectedVenue(value)
        }}
        onChangeView={(value: 'list' | 'map') => setView(value)}
        onRequestLocation={handleRequestLocation}
      />
      <ExploreHomeListView
        initialSearch={search}
        address={address}
        program={program}
        isLoading={isLoading}
        onSelectCategory={(value: string | undefined) => {
          setView('map', value)
        }}
      />
    </div>
  )
}
