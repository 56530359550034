export const ProgramCategories = [
  'academy',
  'all_day',
  'ball_hockey',
  'baseball',
  'basketball',
  'camps',
  'computer_&_tech',
  'day',
  'education',
  'elite_team',
  'engineering',
  'hockey',
  'hockey_development',
  'hockey_shooting',
  'hockey_skills',
  'leagues',
  'martial_arts',
  'multi-sports',
  'power_skating',
  'powerskating',
  'ringette',
  'skating',
  'soccer',
  'sports',
  'spring_academy',
  'summer_camp',
  'tennis',
  'traditional',
  'volleyball',
  'winter_programs',
  'youth_empowerment',
  'other',
]
