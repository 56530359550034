import { useRouter } from 'next/router'
import { Dispatch, useState } from 'react'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { ErrorResponse, ExploreApi, VenueList, Venue } from '@citruscamps/citrus-client'
import { DefaultLocationsPagination } from '../../../constants/pagination'
import { useCookiePreferences } from '../../../hooks/useCookiePreferences'
import { useSearchValue } from '../../../hooks/useSearchValue'
import { useToast } from '../../../hooks/useToast'
import { Pagination } from '../../../interfaces/pagination'
import translations from '../../../translations/en.json'
import { generateApiConfig } from '../../../utils/client-config'
import { generateItemKey, generateListKey } from '../../../utils/key-generator'
import { useRequestHandler } from '../../../hooks/useRequestHandler'

const DefaultFetchProps: FetchProps = {
  sort: 'city',
  order: 'DESC',
  filter: {
    distinct_cities: true,
  },
}

export interface LocationFilterProps {
  distinct_cities?: boolean
}

interface FetchProps {
  sort?: string
  order?: 'ASC' | 'DESC'
  filter: LocationFilterProps
}

interface IProps {
  pagination?: Pagination
  fetchProps?: FetchProps
  overwriteFilters?: boolean
  enabled?: boolean
}

interface FetchLocations {
  data: Venue[]
  error: ErrorResponse | null
  fetchProps: FetchProps
  isError: boolean
  isLoading: boolean
  pagination: Pagination
  search: string
  setFetchProps: (value?: FetchProps) => void
  setPagination: Dispatch<Pagination>
  setSearch: Dispatch<string>
  handleSearchLocations: (search?: string) => Promise<Venue[]>
}

export const useFetchLocations = ({
  pagination: initialPagination,
  overwriteFilters,
  enabled = true,
  ...props
}: IProps): FetchLocations => {
  const { requestHandler } = useRequestHandler()
  const { setToast } = useToast()
  const { asPath } = useRouter()
  const queryClient = useQueryClient()
  const [pagination, setPagination] = useState<Pagination>(
    initialPagination || DefaultLocationsPagination,
  )
  const initialFetchProps: FetchProps = props.fetchProps || DefaultFetchProps
  const [fetchProps, setFetchProps] = useCookiePreferences<FetchProps>(
    `locations-filter-${asPath}`,
    initialFetchProps,
    {
      sameSite: false,
    },
  )
  const { search, deferSearch, setSearch, isSearching } = useSearchValue('', {
    onDefer: () => setPagination(initialPagination || DefaultLocationsPagination),
  })
  const queryKeys = generateListKey({
    type: 'venue',
    pagination,
    sort: fetchProps.sort,
    order: fetchProps.order,
    search: deferSearch,
    query: fetchProps.filter,
  })
  const {
    data: resp,
    isInitialLoading: isLoading,
    isError,
    error,
  } = useQuery<VenueList, ErrorResponse>(
    queryKeys,
    async ({ signal }) => {
      const client = new ExploreApi(generateApiConfig())
      const skip = pagination.limit ? pagination.page * pagination.limit : undefined
      const response = await requestHandler<VenueList>(
        () =>
          client.findAllVenuesExplore(
            {
              limit: pagination.limit,
              skip,
              order: fetchProps.order,
              sort: fetchProps.sort,
              distinct_cities:
                typeof fetchProps?.filter?.distinct_cities !== 'undefined'
                  ? fetchProps?.filter?.distinct_cities
                  : undefined,
            },
            { signal },
          ),
        {
          toast: { setToast },
        },
      )
      response.data.forEach((item) =>
        queryClient.setQueryData(
          generateItemKey({
            type: 'venue',
            id: item.id,
          }),
          item,
        ),
      )
      return response
    },
    { enabled },
  )

  const handleSearchLocations = async (search?: string): Promise<Venue[]> => {
    const client = new ExploreApi(generateApiConfig())
    try {
      const { data } = await requestHandler<VenueList>(() =>
        client.findAllVenuesExplore({
          limit: search ? undefined : 5,
          skip: 0,
          distinct_cities: true,
          search: search || undefined,
        }),
      )
      return data
    } catch (e: any) {
      setToast?.({
        header: (translations as any)['error.default_toast_header'],
        message: e?.message || (translations as any)['error.default_toast_message'],
        type: 'danger',
      })
      throw e
    }
  }

  const _setFetchProps = (val?: FetchProps) => {
    setPagination(initialPagination || DefaultLocationsPagination)
    return setFetchProps(val || DefaultFetchProps)
  }

  return {
    data: resp?.data || [],
    error,
    fetchProps,
    isError,
    isLoading: isLoading || isSearching,
    pagination: {
      ...pagination,
      count: resp?.count || pagination.count,
      total: resp?.total || pagination.total,
      page: resp?.page || pagination.page,
      page_count: resp?.page_count || pagination.page_count,
    },
    search,
    setFetchProps: _setFetchProps,
    setPagination,
    setSearch,
    handleSearchLocations,
  }
}
