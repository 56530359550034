import { ExploreProgram } from '@citruscamps/citrus-client'
import { EventFetchProps, useFetchInfiniteEvents } from '../../../hooks/useFetchEvents'
import { CardSkeleton } from '../../CardSkeleton/CardSkeleton'
import { EventCard } from '../../EventCard/EventCard'
import { ShowMoreButton } from '../../ShowMoreButton/ShowMoreButton'

interface IProps {
  fetchProps?: EventFetchProps
  program?: ExploreProgram
  title: string
}

export const DefaultEventsList = ({ fetchProps, program, title }: IProps) => {
  const {
    data: events,
    isLoading,
    isFetchingNextPage,
    pagination,
    isError,
    fetchNextPage,
  } = useFetchInfiniteEvents({
    ignoreQueries: true,
    fetchProps: {
      ...fetchProps,
      filter: { ...fetchProps?.filter },
    },
    overwriteFilters: true,
  })

  if (isLoading) {
    return (
      <>
        <div className="row mt-5">
          <div className="col">
            <h4>{title}</h4>
          </div>
        </div>
        <div className="row mt-2">
          {Array.from({ length: 3 })
            .fill(null)
            .map((_, i: number) => ['loading_events', i].join(':'))
            .map((key, i) => (
              <div key={key} className="col-sm-12 col-lg-4 my-2 p-md-4">
                <CardSkeleton />
              </div>
            ))}
        </div>
      </>
    )
  }
  if (isError || events.length === 0) {
    return null
  }
  return (
    <>
      <div className="row mt-5">
        <div className="col">
          <h4>{title}</h4>
        </div>
      </div>
      <div className="row">
        {events.map((event, i) => (
          <div key={event.id} className="col-sm-12 col-lg-4 my-2 p-md-4">
            <EventCard
              event={event}
              program={program || event.program}
              showDetails={true}
              trafficSource={{
                utm_source: 'citrus',
                utm_medium: 'internal',
                utm_content: 'explore+home+list',
                utm_term: 'event+card',
              }}
            />
          </div>
        ))}
        {isFetchingNextPage ? (
          Array.from({ length: 3 })
            .fill(null)
            .map((_, i: number) => ['fetching_events', i].join(':'))
            .map((key, i) => (
              <div key={key} className="col-sm-12 col-lg-4 my-2 p-md-4">
                <CardSkeleton />
              </div>
            ))
        ) : (
          <div className="col-12">
            <div className="pt-2 pt-md-0">
              <ShowMoreButton
                {...pagination}
                onChange={(pageParam) => fetchNextPage({ pageParam })}
              />
            </div>
          </div>
        )}
      </div>
    </>
  )
}
