import { faBars } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { HTMLAttributes, useState } from 'react'

interface IProps extends HTMLAttributes<HTMLElement> {
  hostname?: string
}

export const CitrusHeaderNavigation = ({ hostname, ...props }: IProps) => {
  const [showMenu, setShowMenu] = useState<boolean>(false)
  return (
    <nav
      className="navbar navbar-expand-lg justify-content-between navbar-transparent"
      style={{ transition: '0.25s ease' }}
      {...props}
    >
      <div className="container">
        <a
          href={`https://www.citruscamps.com?utm_source=${
            hostname?.replace('http://', '').replace('https://', '') || 'register.citruscamps.com'
          }&utm_campaign=logo`}
          className="navbar-brand py-2 px-3 px-md-0"
        >
          <img src="/imgs/logo-colour.svg" height="24px" alt="Citrus" />
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#toggleNavbar"
          aria-controls="toggleNavbar"
          aria-expanded="false"
          aria-label="Toggle navigation"
          onClick={() => setShowMenu(!showMenu)}
        >
          <FontAwesomeIcon icon={faBars}></FontAwesomeIcon>
        </button>
        <div
          className={`collapse navbar-collapse py-2 px-3${showMenu ? ' show' : ''}`}
          id="toggleNavbar"
        >
          <ul className="navbar-nav ml-auto text-center">
            <li className="nav-item">
              <a
                href={`https://www.citruscamps.com/about-us?utm_source=${
                  hostname?.replace('http://', '').replace('https://', '') ||
                  'register.citruscamps.com'
                }&utm_campaign=about_us`}
                className="nav-link"
              >
                About Us
              </a>
            </li>
            <li className="nav-item">
              <a
                href={`https://www.citruscamps.com?utm_source=${
                  hostname?.replace('http://', '').replace('https://', '') ||
                  'register.citruscamps.com'
                }&utm_campaign=learn_more`}
                className="nav-link btn btn-primary text-white ml-2 my-2 py-1"
              >
                Learn more
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  )
}
