import { faChevronLeft, faChevronRight } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useRef, useState } from 'react'
import { useWindowDimensions } from '../../hooks/useWindowDimensions'
import { useSwipeCarousel } from './hooks/useSwipeCarousel'

interface IProps extends React.HTMLAttributes<HTMLDivElement> {
  columnClassName?: string
  length?: number
}

export const getTotalPages = (length = 0, scrollWidth = 0, clientWidth = 0) => {
  const totalPages = Math.round(scrollWidth / clientWidth) || 0
  if (totalPages <= 1 || length % totalPages !== 0) {
    return totalPages + 1
  }
  return totalPages
}

export const FieldCarousel = ({
  children,
  length = 0,
  columnClassName = 'col-12 col-sm-6 col-md-12 col-lg-6 col-xl-4',
  ...props
}: React.PropsWithChildren<IProps>) => {
  const { width } = useWindowDimensions()
  const carouselRef = useRef<HTMLDivElement>(null)
  const [view, setView] = useState<number>(0)
  const [total, setTotal] = useState<number>(
    carouselRef.current
      ? getTotalPages(length, carouselRef.current.scrollWidth, carouselRef.current.clientWidth)
      : 0,
  )
  const { handleGestureStart, handleGestureMove, handleGestureEnd, listenMouse } = useSwipeCarousel(
    {
      slopValue: (carouselRef.current?.clientWidth || 0) * (1 / 30),
      onLeft: () => next(),
      onRight: () => back(),
    },
  )
  const isFirst = view === 0
  const isLast = view === total - 1
  const moveTo = (newView: number) => {
    setView(newView)
    const offset = carouselRef.current ? carouselRef.current.clientWidth * newView : 0
    carouselRef.current?.scrollTo({ left: offset, behavior: 'smooth' })
  }
  const back = () => {
    if (!isFirst) {
      moveTo(view - 1)
    }
  }
  const next = () => {
    if (!isLast) {
      moveTo(view + 1)
    }
  }

  useEffect(() => {
    setView(0)
    const amount: number = carouselRef.current
      ? getTotalPages(length, carouselRef.current.scrollWidth, carouselRef.current.clientWidth)
      : 0
    if (amount > 0) {
      setTotal(amount)
    }
    carouselRef.current?.scrollTo({ left: 0, behavior: 'smooth' })
  }, [width, setView, setTotal, carouselRef, length])

  return (
    <div {...props}>
      <div className="position-relative mb-3">
        <div
          ref={carouselRef}
          className="carousel-row row mx-4"
          style={{ flexWrap: 'nowrap', overflowX: 'auto', scrollSnapType: 'x mandatory' }}
        >
          {React.Children.map(children, (child, i) => (
            <div
              className={columnClassName}
              key={['component', i].join(':')}
              onPointerDown={handleGestureStart}
              onPointerMove={handleGestureMove}
              onPointerUp={handleGestureEnd}
              onPointerCancel={handleGestureEnd}
              onTouchStart={handleGestureStart}
              onTouchMove={handleGestureMove}
              onTouchEnd={handleGestureEnd}
              onTouchCancel={handleGestureEnd}
              onMouseMove={listenMouse ? handleGestureMove : undefined}
              onMouseUp={listenMouse ? handleGestureEnd : undefined}
              onMouseDown={handleGestureStart}
            >
              {child}
            </div>
          ))}
        </div>
        {!isFirst && (
          <div
            className="position-absolute h-100 top-0 left-0 bottom-0"
            style={{ minWidth: '1.5rem' }}
          >
            <button
              type="button"
              className="btn btn-link h-100 rounded px-0"
              style={{ width: '1.5rem' }}
              onClick={() => back()}
            >
              <FontAwesomeIcon icon={faChevronLeft} />
            </button>
          </div>
        )}
        {!isLast && (
          <div
            className="position-absolute h-100 top-0 right-0 bottom-0"
            style={{ minWidth: '1.5rem' }}
          >
            <button
              type="button"
              style={{ width: '1.5rem' }}
              className="btn btn-link position-absolute h-100 rounded px-0"
              onClick={() => next()}
            >
              <FontAwesomeIcon icon={faChevronRight} />
            </button>
          </div>
        )}
      </div>
      {/* <StepDots
        checkOnComplete={false}
        totalSteps={total}
        currentStep={view + 1}
        onClickStep={(_, i) => moveTo(i)}
      /> */}
    </div>
  )
}
