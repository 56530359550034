export type Bounds = [[number, number], [number, number]]

enum BoundsEnum {
  SOUTH_WEST,
  NORTH_EAST,
}

enum CordinateEnum {
  LONG,
  LAT,
}

export const getBounds = (points: Array<number[]>): Bounds | undefined => {
  if (points.length <= 1) return undefined
  return points.reduce<Bounds | undefined>((bounds, point) => {
    const maxLat: number =
      bounds?.[BoundsEnum.NORTH_EAST][CordinateEnum.LAT] &&
      bounds[BoundsEnum.NORTH_EAST][CordinateEnum.LAT] > point[CordinateEnum.LAT]
        ? bounds[BoundsEnum.NORTH_EAST][CordinateEnum.LAT]
        : point[CordinateEnum.LAT]
    const minLat: number =
      bounds?.[BoundsEnum.SOUTH_WEST][CordinateEnum.LAT] &&
      bounds[BoundsEnum.SOUTH_WEST][CordinateEnum.LAT] < point[CordinateEnum.LAT]
        ? bounds[BoundsEnum.SOUTH_WEST][CordinateEnum.LAT]
        : point[CordinateEnum.LAT]
    const maxLong: number =
      bounds?.[BoundsEnum.NORTH_EAST][CordinateEnum.LONG] &&
      bounds[BoundsEnum.NORTH_EAST][CordinateEnum.LONG] > point[CordinateEnum.LONG]
        ? bounds[BoundsEnum.NORTH_EAST][CordinateEnum.LONG]
        : point[CordinateEnum.LONG]
    const minLong: number =
      bounds?.[BoundsEnum.SOUTH_WEST][CordinateEnum.LONG] &&
      bounds[BoundsEnum.SOUTH_WEST][CordinateEnum.LONG] < point[CordinateEnum.LONG]
        ? bounds[BoundsEnum.SOUTH_WEST][CordinateEnum.LONG]
        : point[CordinateEnum.LONG]
    const southWest: [number, number] = [minLong, minLat]
    const northEast: [number, number] = [maxLong, maxLat]
    return [southWest, northEast]
  }, undefined)
}
