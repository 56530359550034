import { useEffect, useState } from 'react'
import { ExploreProgram } from '@citruscamps/citrus-client'
import { useFetchInfiniteEvents } from '../../../hooks/useFetchEvents'
import { useWindowDimensions } from '../../../hooks/useWindowDimensions'
import { Address } from '../../../utils/address'
import { ExploreHomeMap } from './ExploreHomeMap'
import { ExploreMapEventList } from './ExploreMapEventList'
import { ExploreProgramList } from './ExploreProgramList'

const findMatchCategory = (isTypeCategory: boolean, value?: string): any[] | undefined => {
  if (!value) {
    return undefined
  }
  if (['sports', 'education', 'day', 'traditional'].includes(value)) {
    if (!!isTypeCategory) {
      return [value]
    }
    return undefined
  }
  if (!isTypeCategory) {
    return [value]
  }
  return undefined
}

interface IProps {
  address?: Address
  category?: string
  program?: ExploreProgram
  initialSearch?: string
}

export const ExploreHomeMapView = ({ address, category, program, initialSearch }: IProps) => {
  const { width } = useWindowDimensions()
  const mapHeight = width > 768 ? 590 : 300
  const [deferSearch, setDeferSearch] = useState<string>('')
  const city = address?.city
  const {
    data: events,
    isLoading: isLoadingEvents,
    isError: isEventsError,
    isFetchingNextPage: isFetchingNextEventsPage,
    pagination: eventsPagination,
    fetchNextPage: fetchNextEventsPage,
  } = useFetchInfiniteEvents({
    ignoreQueries: true,
    fetchProps: {
      sort: 'starts_at',
      order: 'ASC',
      filter: {
        type: undefined,
        age: undefined,
        gender: undefined,
        is_online_event: undefined,
        place_name: city,
        tags: [],
        program_type: findMatchCategory(true, category),
        program_tags: findMatchCategory(false, category),
      },
    },
    overwriteFilters: true,
    search: deferSearch,
  })
  useEffect(() => {
    setTimeout(() => {
      setDeferSearch(initialSearch || '')
    }, 700)
  }, [initialSearch])

  if (!width) {
    return null
  }

  return (
    <div>
      <div className="row flex-md-row flex-column-reverse">
        <div className="col-12 col-md-5">
          <ExploreMapEventList
            title={`Upcoming ${category || ''} events ${city ? 'in ' + city : ''}`}
            program={program}
            events={events}
            isLoading={isLoadingEvents}
            isFetchingNextPage={isFetchingNextEventsPage}
            isError={isEventsError}
            pagination={eventsPagination}
            fetchNextPage={fetchNextEventsPage}
          />
          <ExploreProgramList
            title={`Featured ${category || ''} programs ${city ? 'in ' + city : ''}`}
            initialSearch={deferSearch}
            fetchProps={{
              sort: 'name',
              order: 'ASC',
              filter: {
                featured: true,
                type: findMatchCategory(true, category),
                tags: findMatchCategory(false, category),
                place_name: city,
              },
            }}
            mapView={true}
          />
        </div>
        {width > 768 && (
          <div className="col-12 col-md-7">
            <div className="py-4 sticky-top">
              <ExploreHomeMap
                events={events}
                address={address}
                isLoadingEvents={isLoadingEvents}
                mapHeight={mapHeight}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
