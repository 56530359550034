import { DateTime } from 'luxon'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { ErrorResponse, GeocodeAddress, MapboxPlaceType } from '@citruscamps/citrus-client'
import { HttpClient } from '../utils/http'
import { SupportedCountries } from '../constants/application'
import { Address } from '../utils/address'
import { generateBaseKey, generateItemKey } from '../utils/key-generator'
import { getLocalStorageItem, setLocalStorageItem } from '../utils/local-storage'
import { toQueryParams } from '../utils/query-params'

const MapboxAccessToken: string = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN || ''

export interface IpAddressInfo {
  ip: string
  city: string
  region: string
  region_code: string
  country_code: string
  country_code_iso3: string
  country_name: string
  country_capital: string
  country_tld: string
  continent_code: string
  in_eu: boolean
  postal: string
  latitude: number
  longitude: number
  timezone: string
  utc_offset: string
  country_calling_code: string
  currency: string
  currency_name: string
  languages: string
  asn: string
  org: string
}

export interface GeocoderResponse {
  type: string
  query: string[]
  features: GeocodeAddress[]
  attribution: string
  search_time: Date
}

interface IProps {
  types?: MapboxPlaceType[]
  clientIp?: string
}

interface IGeolocation {
  data?: Address
  source?: 'ip_address' | 'geolocation'
  isLoading: boolean
  isError: boolean
  error?: Error
  handleRequestLocation: () => void | Promise<void>
}

export const useGeolocation = ({ types, clientIp }: IProps): IGeolocation => {
  const queryClient = useQueryClient()
  const { data: ipData } = useQuery<IpAddressInfo, ErrorResponse>(
    generateBaseKey({
      type: 'ip_address',
    }),
    async ({ signal }) => {
      const item = getLocalStorageItem('ip_address', DateTime.local().toISODate() || undefined)
      if (item) return item
      const httpClient = new HttpClient({ baseUrl: '/ipapi' })
      let resp: IpAddressInfo
      try {
        resp = await httpClient.get<IpAddressInfo>('/json/', { signal })
      } catch (e) {
        if (!clientIp) throw new Error('Unable to fetch data')
        resp = await httpClient.get<IpAddressInfo>(`/${clientIp}/json/`)
      }
      if (resp) setLocalStorageItem('ip_address', resp, DateTime.local().toISODate() || undefined)
      return resp
    },
  )

  const { data: geolocation, isError: isGeolocationError } = useQuery<
    GeolocationPosition | null,
    Error
  >(
    generateBaseKey({
      type: 'geolocation',
    }),
    async () => {
      const result = await navigator.permissions.query({ name: 'geolocation' })
      if (result.state === 'denied') {
        throw new Error('Failed to get permission.')
      }
      if (result.state === 'prompt') {
        // do not annoy people
        return null
      }
      return await new Promise<GeolocationPosition>((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(
          (pos) => resolve(pos),
          () => reject(new Error('Failed to get permission.')),
        )
      })
    },
  )
  const latitude = geolocation?.coords?.latitude || ipData?.latitude
  const longitude = geolocation?.coords?.longitude || ipData?.longitude

  const {
    data: geocode,
    isInitialLoading: isLoading,
    isError,
    error,
  } = useQuery<GeocodeAddress, Error>(
    generateItemKey({
      type: 'geocoder',
      id: [latitude, longitude].join(),
    }),
    async ({ signal }) => {
      const item = getLocalStorageItem(
        `geocoder.${latitude}.${longitude}`,
        DateTime.local().toISODate() || undefined,
      )
      if (item) return item
      const source: string = 'mapbox.places'
      const country: string = SupportedCountries.join(',')
      const query: string = toQueryParams({
        access_token: MapboxAccessToken,
        types: types?.join(','),
        country,
        limit: 1,
      })
      const baseUrl: string = 'https://api.tiles.mapbox.com'
      const uri: string = `/geocoding/v5/${source}/${longitude},${latitude}.json?${query}`
      const http = new HttpClient({ baseUrl })
      const result: GeocoderResponse = await http.get<GeocoderResponse>(uri, { signal })
      if (result.features[0])
        setLocalStorageItem(
          `geocoder.${latitude}.${longitude}`,
          result.features[0],
          DateTime.local().toISODate() || undefined,
        )
      return result.features[0]
    },
    { enabled: !!longitude && !!latitude },
  )

  const data: Address | undefined = geocode && Address.fromGeocode(geocode)

  const handleRequestLocation = async () => {
    const result = await navigator.permissions.query({ name: 'geolocation' })
    if (result.state === 'prompt') {
      navigator.geolocation.getCurrentPosition(() => {
        queryClient.invalidateQueries(
          generateBaseKey({
            type: 'geolocation',
          }),
        )
      })
    }
  }

  return {
    data,
    source: ipData ? 'ip_address' : geocode || isGeolocationError ? 'geolocation' : undefined,
    isLoading,
    isError,
    error: error || undefined,
    handleRequestLocation,
  }
}
