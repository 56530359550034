import { faLocationCrosshairs, faMapMarkerAlt } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import {
  components,
  ControlProps,
  CSSObjectWithLabel,
  OnChangeValue,
  SingleValueProps,
  Props,
} from 'react-select'
import AsyncSelect from 'react-select/async'
import { Venue } from '@citruscamps/citrus-client'
import {
  addAlpha,
  DefaultCitrusBrandAccentColor,
  DefaultDark,
  DefaultLight,
} from '../../../utils/branding-utils'

export const getCustomStyles = (): Partial<any> => {
  const brand_accent_color: string = DefaultCitrusBrandAccentColor
  return {
    control: (base: CSSObjectWithLabel, state: ControlProps<any, any>) => ({
      ...base,
      border: 'none',
      boxShadow: 'none',
      width: '100%',
    }),
    menu: (base: CSSObjectWithLabel) => ({
      ...base,
      minWidth: '350px',
    }),
    option: (base: CSSObjectWithLabel, state: any) => {
      const selected = state.isSelected || state?.selectProps?.value?.label === state?.label
      return {
        ...base,
        backgroundColor: selected ? brand_accent_color : 'transparent',
        '&:hover': {
          backgroundColor: addAlpha(brand_accent_color, 0.25),
        },
        color: selected ? DefaultLight : state.isFocused ? brand_accent_color : DefaultDark,
      }
    },
    indicatorSeparator: (base: CSSObjectWithLabel) => ({
      ...base,
      display: 'none',
    }),
    indicatorContainer: (base: CSSObjectWithLabel) => ({
      ...base,
      color: brand_accent_color,
    }),
    valueContainer: (base: CSSObjectWithLabel) => ({
      ...base,
      padding: 0,
      width: '100%',
      color: brand_accent_color,
      maxHeight: '40px',
    }),
    placeholder: (base: CSSObjectWithLabel) => {
      return {
        ...base,
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        width: '100%',
      }
    },
    singleValue: (base: CSSObjectWithLabel, props: SingleValueProps<any>) => {
      const opacity = props.isDisabled ? 0.5 : 1
      const transition = 'opacity 300ms'
      return { ...base, opacity, transition, width: '100%' }
    },
    multiValue: (base: CSSObjectWithLabel) => ({
      ...base,
      borderRadius: '3.125rem',
    }),
    multiValueRemove: (base: CSSObjectWithLabel) => ({
      ...base,
      '&:hover': {
        backgroundColor: 'transparent',
      },
      borderRadius: '3.125rem',
    }),
  }
}

const { Control, Option, ValueContainer, DropdownIndicator } = components

const CustomValueContainer = ({ children, ...props }: any) => (
  <ValueContainer {...props}>
    <span className="h2 text-muted d-inline w-100 d-flex">
      {React.Children.map(children, (child) => child || null)}
    </span>
  </ValueContainer>
)

const CustomControl = ({ children, ...props }: any) => (
  <Control {...props}>{React.Children.map(children, (child) => child || null).reverse()}</Control>
)

const VenueSearchRow = ({ value, label }: VenueOption) => (
  <div className="row mx-0 w-100">
    <div className="col-1 text-center py-2 w-100">
      <FontAwesomeIcon icon={faMapMarkerAlt} fixedWidth={true} />
    </div>
    <div className="col w-100">
      <div>{value?.city}</div>
      <div className="small">{[value?.region, value?.country].filter((a) => !!a).join(', ')}</div>
    </div>
  </div>
)

const CustomeOptionContainer = ({ children, data: { value, label }, ...props }: any) => (
  <Option {...props}>{!!value && <VenueSearchRow value={value} label={label} />}</Option>
)

interface VenueOption {
  value?: Venue
  label: string
}

type PropsExtended = Omit<Omit<Props<VenueOption, false>, 'value'>, 'onChange'>

interface IProps extends PropsExtended {
  venue?: Venue
  venues?: Venue[]
  source?: 'ip_address' | 'geolocation'
  onChange: (value?: Venue) => void | Promise<void>
  onBlur?: (event: React.FocusEvent<HTMLElement>) => void | Promise<void>
  onSearchVenues: (search: string) => void | Promise<Venue[]>
  onRequestLocation: () => void | Promise<void>
}

export const VenueSearch = ({
  venue,
  venues = [],
  source,
  onChange: handleChange,
  onBlur: handleBlur,
  onSearchVenues: handleSearchVenues,
  onRequestLocation: handleRequestLocation,
  ...props
}: IProps) => {
  const DropdownIndicatorContainer = ({ children, ...props }: any) => {
    return (
      <DropdownIndicator {...props}>
        {typeof source === 'undefined' ? (
          <button
            type="button"
            className="btn btn-link p-0 fa-xl"
            onClick={() => handleRequestLocation()}
          >
            <FontAwesomeIcon icon={faLocationCrosshairs} className="mr-2 mb-1" fixedWidth={true} />
          </button>
        ) : (
          <span className="fa-xl">
            <FontAwesomeIcon icon={faMapMarkerAlt} className="mr-2 mb-1" fixedWidth={true} />
          </span>
        )}
      </DropdownIndicator>
    )
  }
  return (
    <AsyncSelect
      {...props}
      placeholder="Find a location..."
      onChange={(option: OnChangeValue<VenueOption, false>) => handleChange(option?.value)}
      cacheOptions
      defaultOptions
      loadOptions={async (search: string) => {
        const _venues: Venue[] = (await handleSearchVenues(search)) || []
        return (_venues || []).map((v) => ({
          value: v,
          label: v.city,
        }))
      }}
      value={
        venue
          ? {
              value: venue,
              label: venue.city,
            }
          : undefined
      }
      components={{
        Control: CustomControl,
        Option: CustomeOptionContainer,
        ValueContainer: CustomValueContainer,
        DropdownIndicator: DropdownIndicatorContainer,
        LoadingIndicator: () => null,
      }}
      styles={getCustomStyles()}
      onBlur={handleBlur}
      noOptionsMessage={() => 'No suggestions available.'}
    />
  )
}
