import { faChalkboardTeacher } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { DefaultDetailsPagination } from '../../../constants/pagination'
import { Pagination } from '../../../interfaces/pagination'
import { CardSkeleton } from '../../CardSkeleton/CardSkeleton'
import { ProgramCard } from '../../ProgramCard/ProgramCard'
import { ShowMoreButton } from '../../ShowMoreButton/ShowMoreButton'
import { ProgramFetchProps, useFetchInfiniteExplorePrograms } from '../hooks/useFetchPrograms'

interface IProps {
  title: string
  mapView?: boolean
  fetchProps?: ProgramFetchProps
  initialPagination?: Pagination
  initialSearch?: string
}

export const ExploreProgramList = ({
  title,
  mapView = false,
  initialSearch,
  initialPagination,
  fetchProps,
}: IProps) => {
  const {
    data: programs,
    isLoading,
    isError,
    isFetchingNextPage,
    pagination,
    fetchNextPage,
  } = useFetchInfiniteExplorePrograms({
    fetchProps,
    pagination: initialPagination || DefaultDetailsPagination,
    overwriteFilters: true,
    initialSearch,
  })
  if (isLoading) {
    return (
      <>
        <div className="row mt-5">
          <div className="col">
            <h4>{title}</h4>
          </div>
        </div>
        <div className="row mt-2">
          {Array.from({ length: 3 })
            .fill(null)
            .map((_, i: number) => ['loading_programs', i].join(':'))
            .map((key, i) => (
              <div key={key} className="col-sm-12 col-md-6 col-lg-4 my-2 p-md-4">
                <CardSkeleton />
              </div>
            ))}
        </div>
      </>
    )
  }
  if (isError || programs.length === 0) {
    return (
      <>
        <div className="row mt-5">
          <div className="col">
            <h4>{title}</h4>
          </div>
        </div>
        <div className="row mt-2">
          <div className="col my-5 text-center">
            <div className="mb-3">
              <FontAwesomeIcon icon={faChalkboardTeacher} size="3x" />
            </div>
            <h3>Programs not found</h3>
            <p>We could not find any programs, please try again later.</p>
          </div>
        </div>
      </>
    )
  }

  return (
    <>
      <div className="row mt-5">
        <div className="col">
          <h4>{title}</h4>
        </div>
      </div>
      <div className="row">
        {programs.map((program, i) => (
          <div
            key={program.id}
            className={mapView ? 'col-12 my-2 px-5 py-2' : 'col-sm-12 col-lg-4 my-2 p-md-4'}
          >
            <ProgramCard program={program} />
          </div>
        ))}
        {isFetchingNextPage ? (
          Array.from({ length: 3 })
            .fill(null)
            .map((_, i: number) => ['fetching_programs', i].join(':'))
            .map((key, i) => (
              <div
                key={key}
                className={mapView ? 'col-12 my-2 px-5 py-2' : 'col-sm-12 col-lg-4 my-2 p-md-4'}
              >
                <CardSkeleton />
              </div>
            ))
        ) : (
          <div className="col-12">
            <div className={mapView ? 'my-2 mx-3' : 'pt-2 pt-md-0'}>
              <ShowMoreButton
                {...pagination}
                onChange={(pageParam) => fetchNextPage({ pageParam })}
              />
            </div>
          </div>
        )}
      </div>
    </>
  )
}
